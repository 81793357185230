const slides = {
create: [
"💡\n Välkommen till Skapasidan – där dina behov och idéer möter människor som kan hjälpa till! Forma framtidens marknad för mikrotjänster.",
"🛠️\n Så fungerar det: Skapa ett uppdrag genom att ge det en titel och en beskrivning. Beskriv vilken typ av hjälp du behöver, definiera regler och sätt en deadline.",
"📸\n Gör ditt uppdrag engagerande: Lägg till bilder och nyckelord för att locka rätt personer att hjälpa dig.",
"✅\n Klicka på 'Skapa' för att publicera ditt uppdrag. Se hur andra bidrar med sina färdigheter och hjälp för att förverkliga din idé!",
"🌟\n Behöver du något gjort? Det kan vara allt från trädgårdsarbete och hantverk till kreativa projekt och tekniska lösningar. Skapa ett uppdrag idag!"
],
  game: [
    "🌟\n Välkommen till framtidens marknad för mikrotjänster! Skapa eller utför uppdrag och bidra till en hjälpande gemenskap.",
    "💡\n Behöver du hjälp med något? Från hemmet till tekniska lösningar – publicera ett uppdrag och hitta rätt hjälp snabbt.",
    "🤝\n Utför uppdrag som matchar dina färdigheter och tjäna pengar eller poäng. Bygg din portfölj och skapa värde för dig själv.",
    "🛡️\n En trygg och rättvis plattform: Vi övervakar alla uppdrag för att säkerställa kvalitet och säkerhet för alla parter.",
    "🌍\n Forma framtiden med oss: Varje uppdrag stärker gemenskapen där människor skapar nya möjligheter tillsammans!"
  ],
play: [
"🌍\n Välkommen till Uppdragssidan – här kan du bidra med din tid och dina färdigheter!",
"📝\n Läs uppdragsbeskrivningar: Varje uppdrag har specifika krav och förväntningar. Se till att du förstår vad som behöver göras innan du börjar.",
"📤\n Leverera värde: Skicka in ditt arbete, bifoga bilder eller dokument och vänta på att uppdragsgivaren godkänner.",
"🕒\n Håll koll på dina uppdrag: Följ deras status i kategorier som 'Anbud', 'Pågående', 'Klara' och 'Betalda'.",
"🤝\n Bli belönad: När ditt arbete är godkänt får du antingen pengar eller poäng. Din insats gör skillnad!"
],
profil: [
"🏅\n Välkommen till din Profil – din egen plats på marknaden för mikrotjänster!",
"📊\n Följ din utveckling: Se hur många uppdrag du slutfört, hur mycket du tjänat och dina bidrag till andras framgång.",
"⚙️\n Anpassa din profil: Lägg till en bild, skriv om dina styrkor och visa vad som gör dig unik.",
"🌟\n Sätt upp nya mål: Bygg din karriär genom att bidra till en marknad där samarbete och mångfald står i fokus!"
]
};

export default slides;
