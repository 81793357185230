import React, { useState } from 'react';
import slidesData from './slides'; // Importera slides
import styles from './InfoWindow.module.css'; // Import CSS Module

const InfoWindow = ({ page }) => {
    const [isOpen, setIsOpen] = useState(true); // Börjar öppet
    const [currentSlide, setCurrentSlide] = useState(0);
    const [circleBottom, setCircleBottom] = useState(20); // Startar 20px från botten

    // Hämta slides för den aktuella sidan
    const slides = slidesData[page] || [];

    const toggleWindow = () => {
        setIsOpen((prev) => {
            if (prev) {
                // Om vi stänger fönstret, återställ till första sliden
                setCurrentSlide(0);
            }
            return !prev;
        });
    };

const closePermanently = () => {
    setCurrentSlide(0); // Återställ till första sliden
    setIsOpen(false);
};

const handleOverlayClick = () => {
    setCurrentSlide(0); // Återställ till första sliden
    setIsOpen(false); // Stäng fönstret
};


    const handleNextSlide = () => {
        setCurrentSlide((prev) => {
            if (prev + 1 === slides.length) {
                // Om vi är på sista sliden och klickar "Nästa", stäng fönstret
                setIsOpen(false);
                setCurrentSlide(0); // Återställ till första sliden
                return prev; // Behåll nuvarande slide
            }
            return prev + 1; // Gå till nästa slide
        });
    };

    const handlePreviousSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length); // Går till sista sliden om man är på första
    };

    return (
        <>
        <div className="inforcircle">
            <div
                className={styles.infoCircle}
                onClick={toggleWindow}
                title="Visa information"
                style={{ bottom: `${circleBottom}px` }}
            >
                I
            </div>
        </div>
            {isOpen && (
    <div
        className={styles.overlay}
        onClick={handleOverlayClick} // Återställ och stäng fönstret
    >
        <div
            className={styles.infoWindow}
            onClick={(e) => e.stopPropagation()} // Hindrar att klick inne i fönstret stänger det
        >
            <div className={styles.infoHeader}>
                <h2> </h2>
                <button className={styles.closeButton} onClick={closePermanently}>
                    ×
                </button>
            </div>
            <div className={styles.infoContent}>
                <div className={styles.emoji}>{slides[currentSlide].split(' ')[0]}</div>
                <p className={styles.text}>{slides[currentSlide].substring(slides[currentSlide].indexOf(' ') + 1)}</p>
            </div>
            <div className={styles.dots}>
                {slides.map((_, index) => (
                    <span
                        key={index}
                        className={`${styles.dot} ${index === currentSlide ? styles.activeDot : ''}`}
                    />
                ))}
            </div>
            <div className={styles.infoNavigation}>
                <button
                    onClick={handlePreviousSlide}
                    className={styles.prevButton}
                >
                    Föregående
                </button>
                <button
                    onClick={handleNextSlide}
                    className={styles.nextButton}
                >
                    Nästa
                </button>
            </div>
        </div>
    </div>
)}

        </>
    );
};

export default InfoWindow;
