// App.js
import React, { Suspense, lazy, useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './firebaseConfig';
import { FirebaseReadCounterProvider } from './Pages/utils/FirebaseReadCounterContext';
import { UserProvider, usePoints } from './hooks/UserContext';
import NavigationBar from './NavigationBar';
import useAuth from './hooks/useAuth';
import './App.css';
import { LanguageProvider } from './hooks/LanguageContext';
import Loading from './Loading';
import Modal from './Pages/components/Modal';
import LoginRegister from './Pages/LoginRegister';
import CookieBanner from './CookieBanner';
import { sendAnalyticsData } from './analytics';
import Game from './Pages/Game'; // Import Game directly
import AdminIndicator from './AdminIndicator'; // Importera AdminIndicator
import InfoWindow from './Pages/InfoWindow';


const PrivateRoute = ({ children, user }) => {
    return user ? children : <Navigate to="/" />;
};

const Create = lazy(() => import('./Pages/Create'));
const Play = lazy(() => import('./Pages/PLAY/Play'));
const Profil = lazy(() => import('./Pages/Profil'));
const UserProfile = lazy(() => import('./Pages/UserProfile'));
const Chat = lazy(() => import('./Pages/Chat'));
const Conversations = lazy(() => import('./components/Conversations'));

const AppContent = React.memo(({ setAdminMode }) => { // Lägg till setAdminMode som en prop
    const { user } = useAuth();
    const { loading } = usePoints();
    const [username, setUsername] = useState('');
    const [isRegisteringVisible, setIsRegisteringVisible] = useState(false);
    const [missions, setMissions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const location = useLocation();

    const handleMissionSubmit = useCallback((missionData) => {
        const newMission = { ...missionData, id: missions.length + 1 };
        setMissions((prevMissions) => [...prevMissions, newMission]);
    }, [missions]);

    const handleMissionUpdate = useCallback((updatedMission) => {
        setMissions((prevMissions) => prevMissions.map((mission) => mission.id === updatedMission.id ? updatedMission : mission));
    }, []);

    useEffect(() => {
        if (loading) return;

        sendAnalyticsData({
            cid: '1733173971.1717891423',
            t: 'pageview',
            dp: location.pathname,
            dt: document.title,
        });
    }, [location, loading]);

    useEffect(() => {
        if (user && !initialLoadComplete) {
            const isAdminMode = localStorage.getItem('adminMode') === 'true';
            setAdminMode(isAdminMode);
            setInitialLoadComplete(true);
        }
    }, [user, setAdminMode, initialLoadComplete]);

    if (loading) {
        return <Loading />;
    }

    const handleLoginRegisterClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <NavigationBar 
                user={user} 
                onLoginRegisterClick={handleLoginRegisterClick} 
                username={username}
                isRegisteringVisible={isRegisteringVisible}
            />
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/:lang/" element={<Game />} /> {/* Directly use Game component */}
                    <Route path="/:lang/create" element={<Create onSubmit={handleMissionSubmit} />} />
                    <Route path="/:lang/play/:missionId" element={<Play onMissionUpdate={handleMissionUpdate} />} />
                    <Route path="/:lang/profil" element={<PrivateRoute user={user}><Profil missions={missions} /></PrivateRoute>} />
                    <Route path="/:lang/UserProfile/:userId" element={<UserProfile />} />
                    <Route path="/:lang/chat" element={<PrivateRoute user={user}><Chat /></PrivateRoute>} />
                    <Route path="/:lang/chat/:otherUserId" element={<PrivateRoute user={user}><Chat /></PrivateRoute>} />
                    <Route path="/:lang/conversations" element={<PrivateRoute user={user}><Conversations /></PrivateRoute>} />
                    <Route path="/" element={<Navigate to="/sv/" />} />
                    <Route path="*" element={<Navigate to="/sv/" />} />
                </Routes>
            </Suspense>
            {isModalOpen && (
                <Modal onClose={handleCloseModal}>
                    <LoginRegister 
                        onClose={handleCloseModal} 
                        onUsernameChange={(username) => setUsername(username)} 
                        setIsRegisteringVisible={setIsRegisteringVisible} 
                    />
                </Modal>
                
            )}
        </>
    );
});

function App() {
    const [adminMode, setAdminMode] = useState(false);
    const { user } = useAuth(); // Hämta användarinformation från useAuth

    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-XXXXXXXXXX`; // Replace with your GA4 Measurement ID
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        function gtag(){ window.dataLayer.push(arguments); }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', 'G-BW5PNEV0DV', {
            'cookie_flags': 'SameSite=None;Secure'
        });
        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied'
        });

        const isAdminMode = localStorage.getItem('adminMode') === 'true';
        setAdminMode(isAdminMode);
    }, []);

    useEffect(() => {
        if (user) {
            const isAdminMode = localStorage.getItem('adminMode') === 'true';
            setAdminMode(isAdminMode);
        }
    }, [user]);

    const handleLogout = () => {
        setAdminMode(false);
        window.location.reload();
    };

    return (
        <FirebaseReadCounterProvider>
            <Router>
                <UserProvider>
                    <LanguageProvider>
                        <div className="App">
                            <div className="aurora weakerlight"></div>
                            <CookieBanner /> {/* Flyttad hit */}

                            <AppContent setAdminMode={setAdminMode} /> {/* Lägg till setAdminMode som prop */}
                            {adminMode && <AdminIndicator onLogout={handleLogout} />}

                        </div>
                        
                    </LanguageProvider>

                </UserProvider>
            </Router>
        </FirebaseReadCounterProvider>
    );
}

export default App;
